import { render, staticRenderFns } from "./PageOptionsModal.vue?vue&type=template&id=2946f8d2&scoped=true&"
import script from "./PageOptionsModal.vue?vue&type=script&lang=js&"
export * from "./PageOptionsModal.vue?vue&type=script&lang=js&"
import style0 from "./PageOptionsModal.vue?vue&type=style&index=0&id=2946f8d2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2946f8d2",
  null
  
)

export default component.exports